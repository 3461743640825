<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">ASSIGN APPOINTEES</h4></v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-2">
        <v-flex xs12 md4>
          <v-select
            v-model="district"
            dense
            outlined
            label="Sector/District/Club"
            :items="district_items"
            item-value="id"
            item-text="district_name"
            @change="selected_district"
          ></v-select>
        </v-flex>
      </v-layout>
      <new-officers-in-district
        :key="this.key"
        :name_of_district="name_of_district"
        :district_id="district"
        :is_sector="is_sector"
        :can_edit="true"
      ></new-officers-in-district>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAccountOutline, mdiAccountCashOutline, mdiInformationOutline, mdiPrinter
    , mdiCloudUpload
    , mdiMonitorEye
    , mdiCheckDecagram
  } from '@mdi/js'
  import moment from 'moment'
  import {mapActions, mapGetters} from 'vuex'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import NewOfficersInDistrict from '@/views/admin_interface/NewOfficersInDistrict'

  const initialState = () => {
    return {
      district_items: [],
      is_sector: 0,
      district: 0,
      name_of_district: '',

      key: 0,
      show: false,

      saving_data: false,
      printt: false,
      upload: false,
      can_upload: false,
      auto_deb: false,

      alert: false,
      alert_message: '',
      saving_data_request: false,
      alert_request: false,
      alert_message_request: '',
      total_amount: 0,
      headers: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Request ID', value: 'tin', sortable: false},
        {text: 'Created By', value: 'tin', sortable: false},
        {text: 'Sector', value: 'tin', sortable: false},
        {text: 'Particulars', value: 'last_name', sortable: false},
        {text: 'Description', value: 'last_name', sortable: false},
        {text: 'Amount', value: 'last_name', sortable: false},
      ],
      headers2: [
        {text: 'Voucher#', value: 'date_of_deposit', sortable: false},
        {text: 'Bank', value: 'last_name', sortable: false},
        {text: 'Date', value: 'last_name', sortable: false},
        {text: 'Payee', value: 'tin', sortable: false},
      ],
      data_items_originaldata_items_original: [],
      data_items: [],
      uploaded_data_items: [],
      data_items2: [],
      request_id: '',
      request_items: [],
      category_id: '',
      category_items: [],
      month_of_id: '',
      month_of_items: [],
      allocation_of_fund: '',
      payee: '',
      is_auto_debit: false,
      cheque_no: '',
      cheque_date: '',
      voucher_no: '',
      bank_id: '',
      bank_items: [],
      cheque_items: [],
      vouchered_items: [],
      selectedDepositId: -1,
      has_existing_voucher: false,
      existing_voucher: {},

      can_view_uploaded_cheque: false,
      is_deleting: false,

      no_cheque_uploaded: false,
    }
  }
  export default {
    components: {
      snackBarDialog,
      NewOfficersInDistrict,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiPrinter,
          mdiCloudUpload,
          mdiMonitorEye,
          mdiCheckDecagram,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', [
        'user_id',
        'month_of',
        'name',
        'branch_contact_no',
        'branch_address',
        'position',
      ]),
      ...mapGetters('system_data', [
        'snackbar',
        'snackbar_color',
        'snackbar_text',
        'company_logo',
      ]),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('district', ['list_of_district']),

      initialize_data() {
        this.list_of_district()
          .then(response => {
            this.district_items = response.data
          })
      },
      selected_district(value) {
        this.key++
        var index = this.district_items.map(function (x) {
          return x.id
        }).indexOf(value)
        if (index != -1) {
          this.name_of_district = this.district_items[index].district_name
          this.is_sector = this.district_items[index].is_sector
        }
      },
    },
  }
</script>
